import React from "react"
import { graphql, Link } from "gatsby"
import { css } from "@emotion/react"
import RSSIcon from "../assets/rss.svg"
import SEO from "../components/seo"
import BlogItem from "../components/BlogItem"
import Navbar from "../components/Navbar"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import moment from "moment"
import Layout from "../components/Layout"

export default function BlogListPage({
  data,
  pageContext: { currentPage, skip, numPages },
}) {
  const {
    allAirtable: { edges, totalCount },
  } = data

  const onRandomBlogClick = () => {
    const randomBlog = edges[Math.floor(Math.random() * edges.length)]
    window.open(randomBlog.node.data["URL"])
  }

  console.log(numPages)

  return (
    <Layout>
      <SEO title="All Blogs" description="A list of personal blogs."></SEO>
      <div
        css={css`
          margin-bottom: 30px;
          margin-top: 30px;
        `}
      >
        <h1
          css={css`
            margin-bottom: 0;
            text-align: center;
          `}
        >
          All Blogs
        </h1>
        <span
          css={css`
            display: block;
            margin-bottom: 10px;
            text-align: center;
          `}
        >
          ({totalCount} blogs)
        </span>
      </div>
      <div
        css={css`
          max-width: 600px;
          margin: 0 auto;
          margin-bottom: 50px;
        `}
      >
        {edges.map(edge => {
          const {
            node: { data },
          } = edge
          return <BlogItem key={data["URL"]} data={data} />
        })}
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: center;

            a {
              margin: 0 20px;
              font-size: 18px;
            }
          `}
        >
          {currentPage > 1 && (
            <Link
              to={currentPage === 2 ? "/blogs" : `/blogs/${currentPage - 1}`}
            >
              Previous
            </Link>
          )}
          {currentPage < numPages && (
            <Link to={`/blogs/${currentPage + 1}`}>Next</Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    allAirtable(
      filter: {
        data: {
          Flag: { ne: true }
          RSS: { ne: "no" }
          Last_Post_Date: { ne: null }
          dkb_review: { eq: "approved" }
        }
        table: { eq: "main" }
      }
      sort: { fields: data___LowerName, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          data {
            Name
            URL
            RSS
            Flag
            NotCodingSpecific
            Last_Post_Date
            Last_Post_Title
            Last_Post_URL
            Tags
          }
        }
      }
    }
  }
`
